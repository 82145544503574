export function getTheme(theme: string): string {
  const color_map = {
    Orange: "rgb(244, 64, 31)",
    Yellow: "rgb(252, 188, 43)",
    Blue: "rgb(29, 155, 240)",
    Pink: "rgb(249, 24, 128)",
    Purple: "rgb(120, 86, 255)",
    Green: "rgb(0, 186, 124)"
  };

  return color_map[theme as keyof typeof color_map];
}

export function generateRandomColor(): string {
  const letters = "0123456789ABCDEF";
  let color = "#";

  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}
